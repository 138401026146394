import { omit } from 'lodash';

import { type BillingOption } from '../billing/billing-helpers';
import config from '../universal/config';
import {
  fetchAll,
  fetchAllJSON,
  fetchAllWithCredentials,
  fetchJSONWithCredentials,
} from '../universal/fetch';

export type HeirloomVizType =
  | 'number'
  | 'geckometer'
  | 'line'
  | 'bar'
  | 'column'
  | 'leaderboard'
  | 'table'
  | 'list'
  | 'text'
  | 'highcharts'
  | 'bullet'
  | 'rag'
  | 'ragcolumn'
  | 'funnel'
  | 'pie'
  | 'monitoring'
  | 'map'
  | 'feed';

export interface HeirloomWidgetType {
  id: number;
  title: string;
  deprecated: boolean;
  integration: {
    name: string;
    slug: string;
  };
  superseded: string | null;
  superseded_by: string | null;
  template: { type: HeirloomVizType };
  widget: string;
}

export interface Service {
  // Equivalent to name from other service (e.g. Zendesk Support)
  title: string;
  // Equivalent to slug (e.g. zendesk3)
  name: string;
  auth_type: string;
  options: {
    connectScreen: {
      fields: Array<unknown>;
    };
  };
}

export type Plan = {
  code: string;
  title: string;
  features: {
    max_editors: number | null;
    max_viewers: number | null;
    max_users: number | null;
    max_dashboards: number | null;
    max_screens: number;
    advanced_dashboard_customization: boolean;
    container_layout_custom_colors: boolean;
  } & Record<string, number | string | boolean | DataVolumeLimits | null>;
  price: PlanPrice;
  addons: {
    available: PlanAddon[];
  };
};

// Plans used on the new (02-25) plans page always have editors, viewers and
// data volume limit features. Specify this on the type so that we don't have to
// keep checking for null values.
export type PlanGen0225 = Plan & {
  features: Plan['features'] & {
    max_editors: number;
    max_viewers: number;
    data_volume_limits: DataVolumeLimits;
  };
};

export function isPlanGen0225(
  plan: Plan | PlanGen0225 | undefined,
): plan is PlanGen0225 {
  return plan === undefined || plan?.code.endsWith('_0225');
}

export type DataVolumeLimits = Record<string, number>;

export type PlanAddon = PlanNumericAddon | PlanDataVolumeAddon;

export type PlanNumericAddon = {
  subject: string;
  code: string;
  price: PlanPrice;
  volume: number;
};

export type PlanDataVolumeAddon = {
  subject: 'data_volume';
  code: string;
  price: PlanPrice;
  data_volume_limits: DataVolumeLimits;
};

export function isDataVolumeAddon(
  addon: PlanAddon | undefined,
): addon is PlanDataVolumeAddon {
  return addon?.subject === 'data_volume';
}

export type PlanPrice = {
  annual: number;
  monthly: number;
};

export type SubscriptionAddon = {
  code: string;
  quantity: string;
};

function getServices(query: unknown) {
  const base = `${config.get().ManagementBaseURL}/services`;
  const URL = query ? `${base}?${query}` : base;

  return fetchJSONWithCredentials(URL);
}

function getAllServiceAccounts(serviceName: string) {
  let url = `${config.get().ManagementBaseURL}/accounts`;

  if (serviceName) {
    url += `?service_name=${serviceName}`;
  }

  return fetchJSONWithCredentials(url);
}

function getWidgetsForService(serviceId: string) {
  const url = `${config.get().ManagementBaseURL}/accounts/${serviceId}/widgets`;

  return fetchJSONWithCredentials(url);
}

function getService(serviceId: string) {
  const URL = `${config.get().ManagementBaseURL}/services/${serviceId}`;

  return fetchJSONWithCredentials(URL);
}

function getServiceByName(slug: string): Promise<Service | undefined> {
  const URL = `${config.get().ManagementBaseURL}/services/name/${slug}`;

  return fetchJSONWithCredentials(URL);
}

function getServiceWidgetTypes(serviceName: string) {
  const URL = `${
    config.get().ManagementBaseURL
  }/services/name/${serviceName}/widget_types`;

  return fetchJSONWithCredentials(URL);
}

function getWidgetType(widgetTypeIdOrKey: string): Promise<HeirloomWidgetType> {
  const URL = `${
    config.get().ManagementBaseURL
  }/widget_types/${widgetTypeIdOrKey}`;

  return fetchJSONWithCredentials(URL);
}

function deleteServiceAccount(
  groupId: string,
  serviceId: string,
  deleteAssociatedWidgets: boolean,
) {
  const params = deleteAssociatedWidgets ? '?delete_associated_widgets=1' : '';
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/accounts/${serviceId}${params}`;
  return fetchJSONWithCredentials(URL, { method: 'DELETE' });
}

function createServiceAccount(
  integrationSlug: string,
  accountData: { user: string; password: string; name: string } & Record<
    string,
    unknown
  >,
) {
  const URL = `${config.get().ManagementBaseURL}/accounts`;
  const { user, password, name } = accountData;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      integration_slug: integrationSlug,
      extra: omit(accountData, 'user', 'password', 'name'),
      basic_auth: {
        user,
        password,
      },
      name,
    }),
  }).catch((err) => {
    if (err.status === 409) {
      const message =
        err.user_message || 'The account details entered could not be verified';
      throw new Error(message);
    }
    throw new Error('An error occurred saving the account details');
  });
}

function getDashboard(dashboardId: string) {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/dashboards/${dashboardId}`,
  );
}

function getAllSharingLoops() {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/sharing_loops`,
  );
}

function getUser() {
  return fetchJSONWithCredentials(`${config.get().ManagementBaseURL}/user`);
}

function patchUser(body: unknown) {
  const URL = `${config.get().ManagementBaseURL}/user`;

  return fetchJSONWithCredentials(URL, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function putUser(body: unknown) {
  const URL = `${config.get().ManagementBaseURL}/user`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function putOrganization(id: string, body: unknown) {
  const URL = `${config.get().ManagementBaseURL}/organizations/${id}`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

const deleteOrganizationWithFeedback = (feedback: {
  whyCancel: string;
  whatYouUsingNow: string;
}): Promise<Response> => {
  return fetchAllWithCredentials(`/organizations/delete_with_feedback`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      why_cancel: feedback.whyCancel,
      what_you_using_now: feedback.whatYouUsingNow,
    }),
  });
};

function getPlans(): Promise<(Plan | PlanGen0225)[] | undefined> {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/billing/plans`,
  );
}

function getOrganization(orgId: string) {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/organizations/${orgId}`,
  );
}

function startTrial() {
  const URL = `${config.get().ManagementBaseURL}/billing/start-pro-trial`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
  });
}

function getPairingCode() {
  const URL = `${config.get().ManagementBaseURL}/pairing_codes`;

  return fetchAllJSON(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function getPairingStatus(code: string) {
  const URL = `${config.get().ManagementBaseURL}/pairing_codes/${code}`;

  return fetchAll(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function exchangeDeviceToken(token: string) {
  const URL = `/tv/exchange/${token}`;

  return fetchJSONWithCredentials(URL);
}

function getLogoUploadLink(dashboardId: string, filename: string) {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/logos`;
  const body = JSON.stringify({ filename });
  return fetchJSONWithCredentials(URL, { method: 'POST', body });
}

function createDashboard(groupId: string, dashboard: unknown) {
  const URL = `${config.get().ManagementBaseURL}/groups/${groupId}/dashboards`;
  const body = JSON.stringify(dashboard);
  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });
}

function createWidget(
  dashboardId: string,
  widgetType: number,
  data: { [key: string]: unknown },
): Promise<{ id: number } & { [key: string]: unknown }> {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/widgets`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      layout: {
        // All utility widgets use the same default size. If we use this function for
        // other platforms we might have to call `getDefaultSize` to get the size.
        pixel_width: 230,
        pixel_height: 230,
      },
      widget_type: widgetType,
      config: data,
    }),
  });
}

function getPeople() {
  const URL = `${config.get().ManagementBaseURL}/people`;

  return fetchJSONWithCredentials(URL);
}

function deleteWidget(dashboardId: string, widgetKey: string) {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/widgets/${widgetKey}`;

  return fetchJSONWithCredentials(URL, {
    method: 'DELETE',
  });
}

// The endpoint will create a annual/monthly subscription in Geckoboard and Recurly.
function checkoutPlan({
  planCode,
  recurlyToken,
  threeDSecureToken,
  subscriptionPeriod,
  billingEmail,
  addons,
}: {
  planCode: string;
  recurlyToken: string;
  threeDSecureToken?: string;
  subscriptionPeriod: BillingOption;
  billingEmail: string;
  addons?: SubscriptionAddon[];
}) {
  const URL = `${config.get().ManagementBaseURL}/billing/checkout`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      plan_code: planCode,
      recurly_token: recurlyToken,
      recurly_three_d_secure_token: threeDSecureToken,
      subscription_period: subscriptionPeriod,
      billing_email: billingEmail,
      subscription_add_ons: addons,
    }),
  });
}

function getBillingStatus() {
  const URL = `${config.get().ManagementBaseURL}/billing/status`;

  return fetchJSONWithCredentials(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function regenerateApiKey() {
  const URL = `${config.get().ManagementBaseURL}/user/regenerate_api_key`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function activateUser(fullName: string) {
  const URL = `${config.get().ManagementBaseURL}/user/activate`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      full_name: fullName,
    }),
  });
}

function changePlanOnSubscription({
  planCode,
  subscriptionPeriod,
  addons,
}: {
  planCode: string;
  subscriptionPeriod: BillingOption;
  addons?: SubscriptionAddon[];
}) {
  const URL = `${config.get().ManagementBaseURL}/billing/subscription`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      plan_code: planCode,
      subscription_period: subscriptionPeriod,
      subscription_add_ons: addons,
    }),
  });
}

function getInvoices() {
  const URL = `${config.get().ManagementBaseURL}/billing/invoices`;

  return fetchJSONWithCredentials(URL);
}

export {
  activateUser,
  changePlanOnSubscription,
  checkoutPlan,
  createDashboard,
  createServiceAccount,
  createWidget,
  deleteOrganizationWithFeedback,
  deleteServiceAccount,
  deleteWidget,
  exchangeDeviceToken,
  getAllServiceAccounts,
  getAllSharingLoops,
  getBillingStatus,
  getDashboard,
  getInvoices,
  getLogoUploadLink,
  getOrganization,
  getPairingCode,
  getPairingStatus,
  getPeople,
  getPlans,
  getService,
  getServiceByName,
  getServices,
  getServiceWidgetTypes,
  getUser,
  getWidgetsForService,
  getWidgetType,
  patchUser,
  putOrganization,
  putUser,
  regenerateApiKey,
  startTrial,
};
